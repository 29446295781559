:root{
  --color-fill-interactive-button-primary-default: #9E007E;
  --color-fill-interactive-button-secondary-default: rgba(255, 255, 255, 0.00);
  --color-fill-interactive-input-field-default: #FFF;
  --color-fill-container-tertiary: #EEE;
  --color-fill-disabled: #EEE;
  --color-fill-section-primary: #FFF;
  --color-fill-section-secondary: #FAFAFA;
  --color-fill-section-tertiary: #F5F5F5;
  --color-fill-container-brand: rgba(158, 0, 126, 0.08);
  --color-fill-overlay: rgba(8, 9, 9, 0.64);
  --color-fill-success: rgba(0, 128, 53, 0.08);
  --color-fill-warning: rgba(182, 81, 22, 0.08);
  --color-fill-error: rgba(184, 0, 21, 0.08);
  --color-fill-info: rgba(21, 73, 139, 0.08);
  --color-stroke-container-warning: #B65116;
  --color-stroke-container-info: #15498B;
  --color-stroke-container-primary: #CBCDCD;
  --color-stroke-container-secondary: #E0E0E0;
  --color-stroke-interactive-input-field-default: #8D9496;
  --color-stroke-interactive-default: #CBCDCD;
  --color-stroke-interactive-focus: #9E007E;
  --color-text-primary: #101112;
  --color-text-secondary: #282C2D;
  --color-text-primary-inverse: #FFF;
  --color-text-disabled: #777E80;
  --color-text-success: #008035;
  --color-text-error: #B80015;
  --color-text-warning: #B65116;
  --color-text-info: #15498B;
  --color-text-tertiary: #505759;
  --color-text-interactive-brand-default: #9E007E;
  --primary-colours-vet-blue-1-f-0-f-6-ff: #F0F6FF;
  --Neutrals---color-grayscale-030: #C4C4C4;
  --Neutrals---color-grayscale-080: #4E4E4E;
  --background-background-a-white: #FFF;
  --color-monochrome-15: #EEE;
  --color-brand-primary-50: #9E007E;
  --color-brand-primary-50-inverse: #E62FC1;
  --score-low: #005FEE;
  --score-mid: #FFBB1C;
  --score-high: #008035;
  /* font size */
  --font-size-b1: '18px';
  --font-line-height-b1: '24px';
  --font-size-b2: '16px';
}

*{
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

button{
  font-family: "Open Sans", sans-serif;
  cursor:pointer;
  background:none;
  border:none;
}

a{
  text-decoration: none;
}

ul{
  padding-inline-start: 0;
  margin-block-start: 0;
}

ul li{
  list-style-type: none;
}

h1, h2, h3{
  margin:0;
}

.custom-tooltip-dark{
  opacity:1 !important;
  background-color: #161C29 !important;
  color: #FEFEFE !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  z-index: 2000 !important;
  max-width:240px;
}

.custom-tooltip-light{
  opacity:1 !important;
  border-radius: var(--radius-container-2xs, 8px) !important;
  border: 1px solid var(--color-stroke-container-primary, #CBCDCD) !important;
  background: var(--color-fill-container-primary, #FFF) !important;
  color: var(--color-text-secondary, #282C2D) !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  /* font-weight: 700 !important; */
  line-height: 24px !important;
  z-index: 2000 !important;
  max-width:240px;
}